<template>
  <div class="title">
    …I am terrified by the beautiful things that are going to be created 
  </div>
  <div v-if="score" class="score">
    <h1>{{ score.title }}</h1>
    <div class="score_body" v-html="score.score" />
    <div class="button_container">
      <button @click="getRandomScore">New score</button>
     </div>
  </div>

</template>

<script>


export default {
  name: 'App',
  data() {
    return {
      scores: [],
      score: null
    }
  },

  created () {
    this.scores = require('./data/scores.json')
    this.getRandomScore()
  },
  methods : {
    getRandomScore() {
      let index = Math.floor(Math.random() * (Math.floor(60) - Math.ceil(1) + 1)) + Math.ceil(1) - 1
      this.score = this.scores[index]
      window.scrollTo(0,0);
    }

  }
}
</script>

<style>
@import 'assets/core.min.css';

body {
  font-family: 'Lora';
  text-align: center;
}

h1 {

  font-weight: 700;
  font-size: 2.25rem;

}
.title {
  padding: 2rem 1rem;
  font-style: italic;
  position: absolute;
  top: 2rem;
  width: 100%;
}

.score {
  padding: 1rem;
  bottom: 3rem;
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  top: 25rem;
  right: 0;
  width: 100%;

}
.score_body {
  font-size: 2rem;
  line-height: 2.9rem;
  padding: 4rem 0;
}

.button_container {

  margin-top: 6rem;
  padding-bottom: 3rem;
  width: 100%;
}
button {
  text-transform: uppercase;
  border: none;
  border-bottom: 1px solid black;;
  outline: none;
  font-size: 1.2rem;
}
</style>
